import React from 'react'
import { Card } from '../components/Card'

export function Home({ cardList }) {
  return (
    <>
      <h1>We wrangle spiders!</h1>
      <div className='text-white flex flex-wrap m-8 justify-evenly'>
      {
          cardList.map((item) => {
            return <div key={item.id} className='flex'>
              <Card 
                id={item.id} 
                source={item.imageSource} 
                name={item.name} 
                price={item.price} 
                description={item.description} 
                deal={item.deal ?? null}
                // handleDeleteMe={handleDeleteMe}
                // handleSelectCard={handleSelectCard}
              />
            </div>
          })
        }
      </div>
    </>
  )
}
