import './App.css';
import { Route, Routes, useNavigate, redirect } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
// components
import { Container } from './components/Container.jsx'
import { Navbar } from './components/Navbar';

// pages
import { Home } from './pages/Home'

// lib
import callOuts from './lib/callOuts'

function App() {

  const [cardList, setCardList] = useState([])

  useEffect(() => {
    const getEntries = async () => {
      const { data } = await callOuts.getEntries()
      console.log('theData', data)
      setCardList(data.items)
    }
    getEntries()
  }, [])

  const pageList = [
    {path: '/', text: 'Home'}
  ]
  return (
    <div className="App">
      <Navbar pageList={ pageList } />
      <Container props={
        <Routes >
          <Route path='/' element={<Home cardList={ cardList } />} />
        </Routes>
      }>
      </Container>
    </div>
  );
}

export default App;
